import React, {useEffect, useId, useRef, useState} from 'react';
import * as UI from "./style"
import {useForm} from "react-hook-form";
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import 'react-phone-number-input/style.css'
import emailjs from '@emailjs/browser';
import {ErrorNotify, SuccessNotify} from "./Notifications";
function App() {

    const [modal, setModal] = useState<boolean>(false)
    const form : any = useRef()
    const { register, handleSubmit, control } = useForm()
    const [logo, setLogo] = useState<string>('')
    useEffect(() => {
        const letters = 'oFulfmarketS!@#$%^& '
        const finalText = 'Fulfmarket'
        const arrayFinalText = finalText.split('')
        const arrayLogo = logo.split('')
        if (logo !== finalText) {
            const randomLetter = letters.charAt(Math.floor(Math.random() * letters.length))
            if (arrayLogo[arrayLogo.length - 1] === arrayFinalText[arrayLogo.length -1 ]){
                arrayLogo.push(randomLetter)
                setLogo(arrayLogo.join(''))
                setTimeout(() => {
                    if (arrayLogo[arrayLogo.length - 1]  !== arrayFinalText[arrayLogo.length - 1]) {
                        arrayLogo.pop()
                        setLogo(arrayLogo.join(''))
                    }
                }, 1)
            }
        }
    }, [ logo ])

    const sendForm = () => {
        emailjs.sendForm('Fulfmarket', 'template_tsqbw3g', form.current, 'eA_eU5KYKOHjI-zM3')
            .then((result) => {
                if (result.text === 'OK') {
                    SuccessNotify('Сообщение отправлено!')
                    setModal(false)
                }
            }, (error) => {
                ErrorNotify("Произошла ошибка!")
            });
    }
    const modalForm = useId()
    const ModalBlock = () => {
        return (
            <UI.Modal>
                <form ref={form} id={modalForm} onSubmit={handleSubmit(sendForm)} >
                    <img src={"/images/close.png"} alt={"close"} onClick={() => setModal(!modal)}/>
                    <label>Имя</label>
                    <input type={"text"} {...register("name")}/>
                    <label>Почта</label>
                    <input type={"text"} {...register("email")}/>
                    <label>Телефон</label>
                    <PhoneInputWithCountry
                        name="phoneInputWithCountrySelect"
                        control={control}
                        defaultCountry={"RU"}
                        defaultValue={"+7"}

                        rules={{ required: true }} />
                    <label>Сообщение</label>
                    <input type={"textarea"} {...register("message")}/>
                    <button type={"submit"}>Отправить</button>
                </form>
            </UI.Modal>
        )
    }
    return (
    <div>
        {modal ?
            <ModalBlock/>
        :
        <></>
        }
      <UI.FirstBlock>
        <UI.Header>
            <h1>{logo}</h1>
            <div>
                <a href={"#about"}>О нас</a>
                <a href={"#contacts"}>Контакты</a>
            </div>
        </UI.Header>
        <UI.AboutWebApp>
            <h1>FULFMARKET</h1>
            <p>Фулфилмент для маркетплейсов:
                Wildberries, Ozon, Яндекс.Маркет</p>
            <div>
                <button>
                    <a
                        target={"_blank"}
                        href={"https://docs.google.com/spreadsheets/d/1cpXjBG7CP_0mg9xrzQGN4IFDOjLsnRvFXg3M-eoZQvA/edit#gid=809511057"}>
                        Прайс Москва
                    </a>
                </button>
                <button type={"button"} onClick={() => setModal(!modal)}><h4>Заказать звонок</h4></button>
            </div>
        </UI.AboutWebApp>
        <UI.CountersBlock>
            <div><h1>850+</h1><h2>Поставщиков</h2>
            </div>
            <div><h1>1 720+</h1><h2>Поставок</h2>
            </div>
            <div><h1>2 350 000+</h1><h2>Единиц</h2></div>
        </UI.CountersBlock>
      </UI.FirstBlock>
        <UI.SecondBlock>
            <UI.AboutSecondBlock>
                Мы настолько уверены в качестве своей работы,
                что готовы компенсировать ваши расходы на услуги Wildberries
                по упаковке товара в соответствии с требованиями.
            </UI.AboutSecondBlock>
            {/*// @ts-ignore*/}
            <UI.AboutBlock> <a name="about"></a>МЫ РАБОТАЕМ С:</UI.AboutBlock>
            <UI.BlocksContainer>
                <div>
                    <img src={"/images/wework1.png"} alt={'wework'}/>
                    <h3><span>Новичками</span>
                        , которые только выходят на маркетплейсы и нуждаются в помощи
                    </h3>
                </div>
                <div>
                    <img src={"/images/wework2.png"} alt={'wework'}/>
                    <h3><span>Опытными предпринимателями</span>
                        , которые экономят свое время и делегируют рутинные задачи
                    </h3>
                </div>
                <div>
                    <img src={"/images/wework3.png"} alt={'wework'}/>
                    <h3><span>Крупными организациями</span>
                        , готовыми расширить свой бизнес с помощью новых торговых площадок
                    </h3>
                </div>
            </UI.BlocksContainer>
            <UI.HR/>
            <UI.AboutBlock> АЛГОРИТМ РАБОТЫ:</UI.AboutBlock>
            <UI.BlocksContainer>
                <div>
                    <img src={"/images/algorithm1.png"} alt={'wework'}/>
                    <h2>1. Оставьте заявку</h2>
                    <h3>
                        Наши менеджеры с удовольствием вас
                        проконсультируют, сделают расчет и оформят договор
                    </h3>
                </div>
                <div>
                    <img src={"/images/algorithm2.png"} alt={'wework'}/>
                    <h2>2. Отправьте товар</h2>
                    <h3>
                        Продукцию вы можете отправить к нам любой транспортной компанией
                    </h3>
                </div>
                <div>
                    <img src={"/images/algorithm3.png"} alt={'wework'}/>
                    <h2>3. Подготовка к отправке</h2>
                    <h3>
                        Примем товар на складе, проверим на брак,
                        упакуем и промаркируем по требованиям маркетплейсов
                    </h3>
                </div>
                <div>
                    <img src={"/images/algorithm4.png"} alt={'wework'}/>
                    <h2>4. Доставка до склада</h2>
                    <h3>
                        Подготовленный груз отправляется на склад выбранной площадки
                    </h3>
                </div>
            </UI.BlocksContainer>
            <UI.HR/>
            <UI.AboutBlock>СОТРУДНИЧАЯ С НАМИ ВЫ ПОЛУЧАЕТЕ:</UI.AboutBlock>
            <UI.ConcatList>
                <UI.ConcatBlock>
                    <img src={"/images/success.png"} alt={'success'}/>
                    <div>
                        <h3>Инфографика для маркетплейсов карточки товара.</h3>
                        <h4>Не нужно тратить время на поиск
                            дизайнера,вы получаете комплексную услугу.</h4>
                    </div>
                </UI.ConcatBlock>
                <UI.ConcatBlock>
                    <img src={"/images/success.png"} alt={'success'}/>
                    <div>
                        <h3>Быстрый результат.</h3>
                        <h4>Мы четко следуем всем условиям маркетплейсов,
                            что уменьшает риск отказа в приемке.</h4>
                    </div>
                </UI.ConcatBlock>
                <UI.ConcatBlock>
                    <img src={"/images/success.png"} alt={'success'}/>
                    <div>
                        <h3>Экономия времени.</h3>
                        <h4>Делегируя рутинные задачи, вы растете сами и растет ваш бизнес.</h4>
                    </div>
                </UI.ConcatBlock>
                <UI.ConcatBlock>
                    <img src={"/images/success.png"} alt={'success'}/>
                    <div>
                        <h3>Экономия бюджета.</h3>
                        <h4>Вам не потребуется склад для хранения товаров,
                            расходные материалы для упаковки, наемные сотрудники</h4>
                    </div>
                </UI.ConcatBlock>
                <UI.ConcatBlock>
                    <img src={"/images/success.png"} alt={'success'}/>
                    <div>
                        <h3>Возможность заказать товар из Китая.</h3>
                        <h4>Вам не придется искать поставщиков и байеров в Китае
                            , тем самым вы экономите время и получаете конкурентную цену.</h4>
                    </div>
                </UI.ConcatBlock>
            </UI.ConcatList>
            <UI.HR/>
            <UI.AboutBlock>НАША<span> ЛОГИСТИКА</span></UI.AboutBlock>
            <UI.LogisticBlock>
                <UI.Logistic>
                    <h2>WILDBERRIES:</h2>
                    <ul>
                        <li><img src={"/images/success.png"} alt={"suc"}/>Электросталь</li>
                        <li><img src={"/images/success.png"} alt={"suc"}/>Подольск</li>
                        <li><img src={"/images/success.png"} alt={"suc"}/>Коледино</li>
                        <li><img src={"/images/success.png"} alt={"suc"}/>Казань</li>
                        <li><img src={"/images/success.png"} alt={"suc"}/>Краснодар</li>
                        <li><img src={"/images/success.png"} alt={"suc"}/>Санкт - Петербург</li>
                        <li><img src={"/images/success.png"} alt={"suc"}/>Сц Иваново</li>
                        <li><img src={"/images/success.png"} alt={"suc"}/>Тула</li>
                        <li><img src={"/images/success.png"} alt={"suc"}/>Екатеринбург</li>
                        <li><img src={"/images/success.png"} alt={"suc"}/>Новосибирск</li>
                    </ul>
                </UI.Logistic>
                <UI.Logistic>
                    <h2>OZON:</h2>
                    <ul>
                        <li><img src={"/images/success.png"} alt={"suc"}/>Хоругвино</li>
                        <li><img src={"/images/success.png"} alt={"suc"}/>Петровское (бывшая новая Рига)</li>
                        <li><img src={"/images/success.png"} alt={"suc"}/>Пушкино 1</li>
                        <li><img src={"/images/success.png"} alt={"suc"}/>Пушкино 2</li>
                        <li><img src={"/images/success.png"} alt={"suc"}/>Саларьево</li>
                        <li><img src={"/images/success.png"} alt={"suc"}/>Скотопрогонная</li>
                    </ul>
                </UI.Logistic>
            </UI.LogisticBlock>
            <UI.HR/>
            <UI.AboutBlock><span>МЫ РАБОТАЕМ</span> ПО ВСЕЙ РОССИИ И СТРАНАМИ СНГ</UI.AboutBlock>
            <UI.GreatRussia>
                <div>
                    <h3>Карта поставщиков Fulfmarket</h3>
                    <span>Более 500 городов</span>
                    <p>
                        <img src={"/images/locations.png"} alt={"location"}/>
                        Москва, Санкт-Петербург, Нижний Новгород, Ростов, Екатеринбург,
                        Новосибирск, Краснодар, Челябинск, Ярославль, Волгоград, Белгород, Воронеж,
                        Иркутск, Тюмень, Калуга, Самара, Тула, Ялта, Уфа, Кемерово и другие
                    </p>
                </div>
                <img src={"/images/map.png"} alt={'map'}/>
            </UI.GreatRussia>
            <UI.HR/>
            <UI.AboutBlock>ПРИСОЕДИНЯЙТЕСЬ К НАШЕМУ КАНАЛУ,<br/>
                <span>БУДЬТЕ В КУРСЕ ПОСЛЕДНИХ НОВОСТЕЙ</span></UI.AboutBlock>
            <UI.Telegram>
                <img src={"/images/channeltg.jpeg"} alt={'channel'}/>
                <img src={"/images/chattg.jpeg"} alt={'channel'}/>
            </UI.Telegram>
            <UI.HR/>
            <UI.AboutBlock>НАША КОМАНДА</UI.AboutBlock>
            <UI.Team>
                <UI.Mate>
                    <img src={"/images/1.png"} alt={'Mate'}/>
                    <h3>Денис Болдарев
                        Основатель фулфилмента
                        Руководитель</h3>
                    <a href={"+79647930239"}>
                        +7(964)793-02-39</a>
                </UI.Mate>
                <UI.Mate>
                    <img src={"/images/2.png"} alt={'Mate'}/>
                    <h3>Сергей Подлинов
                        Основатель фулфилмента
                        Руководитель</h3>
                    <a href={"+79858626967"}>
                        +7(985)862-69-67</a>
                </UI.Mate>
                <UI.Mate>
                    <img src={"/images/3.png"} alt={'Mate'}/>
                    <h3>Максим Неделя
                        Руководитель склада</h3>
                    <a href={"+79269404414"}>
                        +7(926)940-44-14</a>
                </UI.Mate>
                <UI.Mate>
                    <img src={"/images/4.png"} alt={'Mate'}/>
                    <h3>Наталья Игнатова
                        Менеджер</h3>
                    <a href={"+79610796537"}>
                        +7(961)079-65-37</a>
                </UI.Mate>
                <UI.Mate>
                    <img src={"/images/5.png"} alt={'Mate'}/>
                    <h3>Марина Мартынова
                        Товаровед</h3>
                    <a href={"+79647930239"}>
                        +7(929)511-44-96</a>
                </UI.Mate>
            </UI.Team>
            <UI.HR/>
            {/*// @ts-ignore*/}
            <UI.AboutBlock><a name={"contacts"}></a>ОСТАЛИСЬ ВОПРОСЫ?<br/>ОСТАВИТЬ ЗАЯВКУ</UI.AboutBlock>
            <UI.Footer>

                <form ref={form} onSubmit={handleSubmit(sendForm)} >
                    <label>Имя</label>
                    <input type={"text"} {...register("name")}/>
                    <label>Почта</label>
                    <input type={"text"} {...register("email")}/>
                    <label>Телефон</label>
                    <PhoneInputWithCountry
                        name="phoneInputWithCountrySelect"
                        control={control}
                        defaultCountry={"RU"}
                        defaultValue={"+7"}

                        rules={{ required: true }} />
                    <label>Сообщение</label>
                    <input type={"textarea"} {...register("message")}/>
                    <button type={"submit"}>Отправить</button>
                </form>
                <iframe
                    src="https://yandex.ru/map-widget/v1/?um=constructor%3A847fe4aa849581ff0fdcc4850dabbf6d3299c71a0be127e819b007e97d2fe532&amp;source=constructor"
                    height="300" frameBorder="0"></iframe>
                <UI.Contacts>
                    <div>
                        <img src={"/images/address.png"} alt={"address"}/>
                        <address>143960, Россия, Московская область,
                            город Реутов, улица Фабричная, дом 8</address>
                    </div>
                    <div>
                        <img src={"/images/telegram_icon.png"} alt={"telegram"}/>
                        <address>Telegram/WhatsApp:<br/>
                            <a href={"+79858626967"}>+7(985)-862-69-67</a> / <a href={"+79647930239"}>+7(964)-793-02-39</a></address>
                    </div>
                    <div>
                        <img src={"/images/email_icon.png"} alt={"email"}/>
                        <a href={"mailto:fulfmarket@gmail.com"}>fulfmarket.com</a>
                    </div>
                    <div>
                        <img src={"/images/instagram_logo.png"} alt={"instagram"}/>
                        <address>Instagram</address>
                    </div>
                </UI.Contacts>
            </UI.Footer>
        </UI.SecondBlock>
    </div>
  );
}

export default App;
